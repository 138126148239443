import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Category_services from "../Services/category_services";
import Search from "../Views/Products/Components/Search";

const Header = () => {
    // const [showLoginModal, setShowLoginModal] = useState(false);
    const [categories, setCategories] = useState([]);
    const [hoveredCategory, setHoveredCategory] = useState(null);
    const [hoveredSubCategory, setHoveredSubCategory] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Sidebar toggle state
    const sidebarRef = useRef(null); // Reference to the sidebar
    const location = useLocation();
    const [activeMenu, setActiveMenu] = useState(null);

    const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar visibility

    useEffect(() => {
        // Close sidebar if clicked outside of it
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setIsSidebarOpen(false);
            }
        };

        if (isSidebarOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isSidebarOpen]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await Category_services.getCategories();
                setCategories(response.data);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };
        fetchCategories();
    }, []);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    // Detect screen size change
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Static data for account types and their options
    const accountTypes = [
        {
            id: 1,
            name: 'Business',
            options: [
                { id: 'onboarding', name: 'Onboarding', link: '/vendor/onboarding' },
                { id: 'login', name: 'Login', link: '/vendor/login' }
            ]
        },
        {
            id: 2,
            name: 'User',
            options: [
                { id: 'onboarding', name: 'Register', link: '/user/login' },
                { id: 'login', name: 'Login', link: '/user/login' }
            ]
        }
    ];

    const handleClick = (id) => {
        // Toggle active menu; if the same menu is clicked, close it
        setActiveMenu(activeMenu === id ? null : id);
    };

    return (
        <header id="header" className="header d-flex align-items-center fixed-top bg-black mobile-head-transparent">
            <div className="container-fluid container-xl position-relative d-flex flex-column flex-md-row align-items-center">
                {/* First row for mobile (Logo and Toggle Button) */}
                <div className="row w-100 d-md-none justify-content-between align-items-center mb-2 mobile-menu-first-row-height">
                    <div className="col-6">
                        <a href="/" className="logo d-flex align-items-center me-auto">
                            <img src={`${process.env.PUBLIC_URL}/assets/img/logo.png`} className="w-60" alt="Logo" />
                        </a>
                    </div>
                    <div className="col-6 text-end">
                        <button className="mobile-menu-toggle" onClick={toggleSidebar}>
                            <i className="fa fa-bars"></i>
                        </button>
                    </div>
                </div>

                {/* Second row for mobile (Search Box) */}
                <div className="row w-100 d-md-none justify-content-center">
                    <div className="col-8">
                        {/* Show the search component only on mobile, regardless of page */}
                        {isMobile && (
                            <Search width="100%" searchBtnRight="5" isMobile={true} isDesktop={false} />
                        )}
                    </div>
                </div>

                {/* Desktop view layout */}
                <div className="d-none d-md-flex w-100 justify-content-between align-items-center">
                    <div className="col-md-2">
                        <a href="/" className="logo d-flex align-items-center me-auto">
                            <img src={`${process.env.PUBLIC_URL}/assets/img/logo.png`} alt="Logo" className="w-100" />
                        </a>
                    </div>

                    <div className="col-md-4 d-flex align-items-center justify-content-center">
                        {location.pathname !== "/" && (
                            <Search width="20em" searchBtnRight="3%" isMobile={false} isDesktop={true}/>
                        )}
                    </div>

                    <div className="col-md-6">
                        <ul className="exo-menu">
                            {/*<li><a href="/"><i className="fa fa-home"></i> Home</a></li>*/}
                            <li className="mega-drop-down menu-border">
                                <a href="#" className="category-link">Categories</a>
                                <div
                                    className="animated fadeIn mega-menu"
                                    onMouseEnter={() => setHoveredCategory(hoveredCategory)}  // Keeps hoveredCategory state active
                                    onMouseLeave={() => { setHoveredCategory(null); setHoveredSubCategory(null); }}  // Reset when mouse leaves the whole menu
                                >
                                    <div className="mega-menu-wrap">
                                        <div className="row">
                                            {/* Categories Column */}
                                            <div className="col-md-4">
                                                <h4 className="row mega-title">Categories</h4>
                                                <ul className="description">
                                                    {categories.map(category => (
                                                        <li key={category.id}
                                                            onMouseEnter={() => setHoveredCategory(category)}
                                                        >
                                                            <a href={`/category/${category.id}/products`}>
                                                                {category.name}
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>

                                            {/* Subcategories Column - Only show if category is hovered */}
                                            {hoveredCategory && (
                                                <div className="col-md-4">
                                                    <h4 className="row mega-title">Sub Categories</h4>
                                                    <ul className="description">
                                                        {hoveredCategory.subcategories.map(subcategory => (
                                                            <li key={subcategory.id}
                                                                onMouseEnter={() => setHoveredSubCategory(subcategory)}
                                                            >
                                                                <a href={`/sub_category/${subcategory.id}/products`}>
                                                                    {subcategory.name}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}

                                            {hoveredSubCategory && (
                                                <div className="col-md-4">
                                                    <h4 className="row mega-title">Topics</h4>
                                                    <ul className="description">
                                                        {hoveredSubCategory.topics.map(topic => (
                                                            <li key={topic.id}>
                                                                <a href={`/topic/${topic.id}/products`}>
                                                                    {topic.topic_name.charAt(0).toUpperCase() + topic.topic_name.slice(1).toLowerCase()}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="menu-border"><a href="#contact-us" className="category-link">Contact</a></li>
                            <li className="drop-down menu-border"><a href="#" className="category-link">Business Login</a>
                                <ul className="drop-down-ul animated fadeIn">
                                    <li className="no-list-style"><a href="/vendor/onboarding" className="auth-submenu">Onboarding</a></li>
                                    <li className="no-list-style"><a href="/vendor/login" className="auth-submenu">Existing User</a></li>
                                </ul>
                            </li>
                            <li className="drop-down menu-border"><a href="#" className="category-link">User Login</a>
                                <ul className="drop-down-ul animated fadeIn">
                                    <li className="no-list-style"><a href="/user/login" className="auth-submenu">Login</a></li>
                                    <li className="no-list-style"><a href="/user/login" className="auth-submenu">Register</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>


                    {/* Mobile Sidebar */}
                    <div ref={sidebarRef} className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
                        <button className="close-btn" onClick={toggleSidebar}>
                            <i className="fa fa-times"></i>
                        </button>
                        <div className="sidebar-content">
                            {/* Sidebar content */}
                        </div>
                    </div>
                </div>
            </div>


            {/* Sidebar for mobile menu */}
            <div ref={sidebarRef} className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
                <button className="close-btn" onClick={toggleSidebar}>
                    <i className="fa fa-times"></i>
                </button>
                <div className="sidebar-content">
                    <h4 className="clr-white">Categories</h4>
                    <ul>
                        {categories.map(category => (
                            <li key={category.id} onClick={() => setHoveredCategory(category)}>
                                {category.name}
                                {/* Subcategories */}
                                {hoveredCategory === category && (
                                    <ul className="subcategories">
                                        {category.subcategories.map(subcategory => (
                                            <a href={`/sub_category/${subcategory.id}/products`}> <li key={subcategory.id}>{subcategory.name}</li></a>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                    <hr />
                    <a href="#" className="sidebar-link">Contact</a>

                    <hr/>
                    <ul className="accordion-menu">
                        {accountTypes.map((accountType) => (
                            <li key={accountType.id}>
                                <div
                                    className="menu-header"
                                    onClick={() => handleClick(accountType.id)}
                                    // style={{
                                    //     cursor: 'pointer',
                                    //     padding: '10px',
                                    //     background: '#f0f0f0',
                                    //     borderBottom: '1px solid #ccc',
                                    // }}
                                >
                                    {accountType.name}
                                </div>
                                {activeMenu === accountType.id && (
                                    <ul className="submenu">
                                        {accountType.options.map((option) => (
                                            <li key={option.id} style={{ margin: '5px 0' }}>
                                                <a href={option.link} style={{ textDecoration: 'none', color: '#fff' }}>
                                                    {option.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            {/*<LoginModal show={showLoginModal} handleClose={handleClose} />*/}
        </header>
    );
};

export default Header;

