import http from "../http_common";

class CategoryService {
    getCategories() {
        return http.get(`/categories/`);
    }

    getCategoriesByID(id) {
        return http.get(`/categories/`, id);
    }

    getMenuCategories = (params) => {
        return http.get(`/products/filter/`, { params });
    };

}

export default new CategoryService();