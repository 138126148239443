import React , {Suspense} from 'react'
import './App.css';
import Base from './Layouts/base'
import { BrowserRouter as Router , Switch , Route } from 'react-router-dom'

function App() {
  return (
      <React.Fragment>
        <Router basename="/">
          <Suspense>
            <Switch>
              <Route path="/" name="Home" render={props => <Base {...props}/>} />
            </Switch>
          </Suspense>
        </Router>
      </React.Fragment>
  );
}

export default App;
