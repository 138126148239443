import { lazy } from 'react';

const Home = lazy(() => import("../src/Views/index"));
const SearchProducts = lazy(() => import("../src/Views/Products/search_products"));
const CategoryProducts = lazy(() => import("../src/Views/Products/category_products"));
const ProductDetail = lazy(() => import("../src/Views/Products/product_detail"));
// const Traffic = lazy(() => import("../src/Views/Traffic"));
// const Dashboard = lazy(() => import("../src/Views/User/Dashboard"));

const VendorOnboarding = lazy(() => import("../src/Views/Onboarding/VendorOnboarding"));

// Auth
const VendorSignUpSignInForm = lazy(() => import("./Views/Auth/Vendors/Login"));
const VendorDashboard = lazy(() => import("../src/Views/Auth/Vendors/Dashboard"));
const VendorProfileSummary = lazy(() => import("../src/Views/Auth/Vendors/Pages/ProfileSummary"));
const VendorAddNewProduct = lazy(() => import("../src/Views/Auth/Vendors/Pages/Products/AddNewProduct"));
const VendorAddNewLead = lazy(() => import("../src/Views/Auth/Vendors/Pages/Leads/Leads"));
const VendorUpdateNotification = lazy(() => import("../src/Views/Auth/Vendors/Pages/PlatformNotification/Notifications"));
const VendorPerformanceAnalytics = lazy(() => import("../src/Views/Auth/Vendors/Pages/PerformanceAnalytics/PerformanceAnalytics"));
const VendorCreateNewPromotion = lazy(() => import("../src/Views/Auth/Vendors/Pages/MarktingPromotions/CreateNewPromotion"));
const VendorCustomerEngagementStats = lazy(() => import("../src/Views/Auth/Vendors/Pages/CustomerEngagementStats/CustomerEngagementStats"));
const VendorNewComplianceAndCertifications = lazy(() => import("../src/Views/Auth/Vendors/Pages/ComplianceAndCertifications/ComplianceAndCertifications"));
const VendorNewOrderAndTransactionHistory = lazy(() => import("../src/Views/Auth/Vendors/Pages/OrderAndTransactionHistory/OrderAndTransactionHistory"));
const VendorNewCustomerInteraction = lazy(() => import("../src/Views/Auth/Vendors/Pages/CustomerInteraction/CustomerInteraction"));

// User
const SignUpSignInForm = lazy(() => import("./Views/Auth/Users/Login"));
const UserProfile = lazy(() => import("../src/Views/Auth/Users/Profile"));
const MyWishlist = lazy(() => import("../src/Views/Auth/Users/MyWishlist"));
export const Routes = [
    {
        path: "/",
        component: Home,
        exact: true
    },
    {
        path: "/products/search",
        component: SearchProducts,
        exact: true
    },
    {
        path: "/category/:categoryId/products",
        component: CategoryProducts,
        exact: true
    },
    {
        path: "/sub_category/:subCategoryId/products",
        component: CategoryProducts,
        exact: true
    },
    {
        path: "/topic/:topicId/products",
        component: CategoryProducts,
        exact: true
    },
    {
        path: "/product/:id/detail",
        component: ProductDetail,
        exact: true
    },
    {
        path: "/vendor/onboarding",
        component: VendorOnboarding,
        exact: true
    },
    {
        path: "/vendor/login",
        component: VendorSignUpSignInForm,
        exact: true
    },
    {
        path: "/vendor/dashboard",
        component: VendorDashboard,
        exact: true
    },
    {
        path: "/vendor/profile_summary",
        component: VendorProfileSummary,
        exact: true
    },
    {
        path: "/vendor/product/new",
        component: VendorAddNewProduct,
        exact: true
    },
    {
        path: "/vendor/lead/new",
        component: VendorAddNewLead,
        exact: true
    },
    {
        path: "/vendor/notifications",
        component: VendorUpdateNotification,
        exact: true
    },
    {
        path: "/vendor/performance_analytics",
        component: VendorPerformanceAnalytics,
        exact: true
    },
    {
        path: "/vendor/marketing_promotions/new",
        component: VendorCreateNewPromotion,
        exact: true
    },
    {
        path: "/vendor/customer_engagement_stats",
        component: VendorCustomerEngagementStats,
        exact: true
    },
    {
        path: "/vendor/certifications/new",
        component: VendorNewComplianceAndCertifications,
        exact: true
    },
    {
        path: "/vendor/order_transaction_history/new",
        component: VendorNewOrderAndTransactionHistory,
        exact: true
    },
    {
        path: "/vendor/customer_interaction/new",
        component: VendorNewCustomerInteraction,
        exact: true
    },
    {
        path: "/user/login",
        component: SignUpSignInForm,
        exact: true
    },
    {
        path: "/user/profile",
        component: UserProfile,
        exact: true
    },
    {
        path: "/user/wishlist",
        component: MyWishlist,
        exact: true
    }
];
