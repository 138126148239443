import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Routes } from '../routes';
import Header from "./header"; // Adjust the path if necessary
import Footer from "./footer"; // Adjust the path if necessary

function App() {
    return (
        <Router>
            <Header />
            <Suspense>
                <main className="main">
                    <Switch>
                        {Routes.map((route, index) => (
                            <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                component={route.component}
                            />
                        ))}
                    </Switch>
                </main>
            </Suspense>
            <Footer />
        </Router>
    );
}

export default App;
