import React, { useRef, useState } from "react";
import {useLocation} from "react-router-dom";

const Footer = () => {
    const formRef = useRef(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const location = useLocation();

    const handleSubmit = (event) => {
        event.preventDefault();

        const form = formRef.current;
        const formData = new FormData(form);
        const formObject = {};

        formData.forEach((value, key) => {
            formObject[key] = value;
        });

        // Add additional fields to the formObject
        formObject.to_address = 'contact@venturecube.ai'; // Replace with the desired email address
        formObject.venture_name = 'Eco Search';
        formObject.logo = 'https://www.ecosearch.ai/assets/img/black-logo.png';
        formObject.bg_clr = '#86E900';

        // Show the loader and disable the submit button
        setIsSubmitting(true);

        fetch('https://email-api-132l.onrender.com/send_email.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formObject),
        })
            .then((response) => response.json())
            .then((data) => {
                // Hide the loader
                setIsSubmitting(false);

                if (data.success) {
                    setSuccessMessage('Your message has been sent successfully!');
                    setErrorMessage('');
                    form.reset();
                } else {
                    setErrorMessage('There was an error sending your message. Please try again later.');
                    setSuccessMessage('');
                }
            })
            .catch((error) => {
                console.error('Error:', error);

                // Hide the loader
                setIsSubmitting(false);
                setErrorMessage('There was an error sending your message. Please try again later.');
                setSuccessMessage('');
            });
    };

    return (
        <>
        <footer id="contact-us" className="footer position-relative bg-black">
            <div className="container footer-top">
                <div className="row gy-4">
                    <div className="col-lg-6 col-md-7 footer-about" data-aos="fade-right">
                        <img src="./assets/img/logo.png" alt="" className="mb-md-5 mb-lg-5 mobile-pad-bottom-5per w-50" />
                        <form ref={formRef} onSubmit={handleSubmit} className="php-email-form">
                            <div className="row">
                                <div className="form-group col-md-12 mb-md-4 mb-lg-4" data-aos="fade-right">
                                    <input type="text" className="form-control bg-transparent contact-form-input" name="firstName" placeholder="Name" required />
                                </div>
                                <div className="form-group col-md-6 mb-md-4 mb-lg-4" data-aos="fade-right">
                                    <input type="text" className="form-control bg-transparent contact-form-input" name="mobile" placeholder="Mobile" required />
                                </div>
                                <div className="form-group col-md-6 mb-md-4 mb-lg-4" data-aos="fade-right">
                                    <input type="email" className="form-control bg-transparent contact-form-input" name="email" placeholder="Email" required />
                                </div>
                                <div className="form-group col-md-12 mb-md-4 mb-lg-4" data-aos="fade-right">
                                    <textarea className="form-control bg-transparent contact-form-text-area" name="description" placeholder="Description" cols="30" rows="5" required></textarea>
                                </div>

                                {successMessage && (
                                    <div id="successMessage" style={{ color: 'green', textAlign: 'right', fontSize: '24px', fontWeight: '500' }} className="mb-3">
                                        {successMessage}
                                    </div>
                                )}
                                {errorMessage && (
                                    <div id="errorMessage" style={{ color: 'red', textAlign: 'right', fontSize: '24px', fontWeight: '500' }} className="mb-3">
                                        {errorMessage}
                                    </div>
                                )}

                                {isSubmitting && (
                                    <div id="loader" style={{ color: 'white', textAlign: 'right' }} className="clr-white text-right">
                                        Processing...
                                    </div>
                                )}

                                <div className="text-right mobile-margin-top">
                                    <button type="submit" className="btn btn-theme-default">Send</button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="col-md-2 col-lg-2"></div>

                    <div className="col-lg-4 col-md-4">
                        <h1 className="mt-md-4 mt-lg-4 clr-white font40 text-uppercase text-right" data-aos="fade-right">Get In <span className="clr-theme-primary"> Touch</span></h1>
                        <div className="border-line mb-md-3 mb-lg-3"></div>
                        <p className="clr-white font20 text-right font200 contact-content mobile-mar-top-5per">
                            For further inquiries, reach out to us at contact@venturecube.ai or visit our website at
                        </p>

                        <p className="clr-white text-right font20 contact-content">
                            <a className="clr-white " href="mailto:contact@venturecube.ai">
                                contact@venturecube.ai &nbsp;&nbsp;
                                <i className="clr-theme-primary fa-solid fa-envelope"></i>
                            </a>
                        </p>
                        <p className="clr-white text-right font20 contact-content">
                            <a className="clr-white " href="www.ecosearch.ai">
                                www.ecosearch.ai &nbsp;&nbsp;
                                <i className="clr-theme-primary fa-solid fa-globe"></i>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
        </>
    );
};

export default Footer;
